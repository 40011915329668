<template>
  <div>
    <portal to="body-end">

      <b-sidebar
          id="sidebar-add-new-event"
          sidebar-class="sidebar-xl"
          v-model="sidebar.visible"
          bg-variant="white"
          shadow
          backdrop
          no-header
          width="1200px"
          right
          @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0" v-if="loading == null && contractId != null">
            {{ contract.moveDate  | moment('DD/MM/YY') }} - {{ contract.client.firstname }} {{
              contract.client.lastname
            }} - {{ property.address }}
          </h5>
          <h5 class="mb-0" v-else>
            {{ msg('Loading') }}...
          </h5>
          <div>
            <div class="nav-link d-inline-block" role="presentation">
              <a :href="`/real_estate/contracts/rent/${contractId}`" target="_blank">
                <b><i class="fa-solid fa-arrow-up-right-from-square"></i></b>
                <!--                  {{msg("Open in new tab")}}-->
              </a>
            </div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="sidebar.visible = false"/>
          </div>
        </div>
        <!-- Content -->
        <div v-if="loading==null && contractId != null">

          <b-tabs v-model="tabsIndex" align="center">
            <b-tab :title="msg('Contract')" active>
              <div v-if="contract != null">
                <div>
                  <div>
                    <!--                  <feather-icon icon="FileIcon" size="19"/>
                                      <h4 class="mb-0 ml-50 text-capitalize d-block">
                                        {{ msg('Contract').toUpperCase() }}
                                        <span style="color: #f3a152">{{ (contract != null && contract.bnbStyleRent != null && contract.bnbStyleRent.active) ? ' (BnbStyle)' : '' }}</span>
                                      </h4>-->
                    <div>
                      <b-table-simple style="width: 100%;">
                        <b-tr>
                          <b-td class="border-top-0"><i class="icon fas fa-tag"></i> Id:</b-td>
                          <b-td class="border-top-0">{{ contract.id }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="border-top-0"><i class="icon fas fa-tag"></i>External Id:</b-td>
                          <b-td class="border-top-0">{{ contract.hostawayReservationId }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("Register date") }}:</b-td>
                          <b-td>{{ $moment(contract.creationTime).format("DD/MM/yy HH:mm") }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user"></i> {{ msg("Client") }}:</b-td>
                          <b-td class="text-capitalize">
                            {{ contract.client.firstname }} {{ contract.client.lastname }}
                            <span v-if="contract.client.birthday != null">
                      ({{ $moment().diff(contract.client.birthday, 'years') }})
                    </span>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-home"></i> {{ msg("Property") }}:</b-td>
                          <b-td>{{ property.address }} {{ property.house }}, {{ property.city }},
                            {{ property.country }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-clipboard"></i> {{ msg("Usage") }}:</b-td>
                          <b-td>{{ contract.use }}</b-td>
                        </b-tr>
                        <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                          <b-td><i class="icon fas fa-user"></i> {{ msg("Platform") }}:</b-td>
                          <b-td>
                            {{ contract.bnbStyleRent.platform }}
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-user"></i> {{ msg("Landlord Firstname") }}:</b-td>
                          <b-td>
                            {{ contract.landlord.firstname }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user"></i> {{ msg("Landlord Lastname") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              {{ contract.landlord.lastname }}
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Period price") }}:</b-td>
                          <b-td>
                            {{ contract.bnbStyleRent.totalPayment }} <strong>€</strong>
                          </b-td>
                        </b-tr>

                        <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("periodpriceafterdis") }}:</b-td>
                          <b-td>
                            {{ contract.bnbStyleRent.totalPaymentDiscount }} <strong>€</strong>
                          </b-td>
                        </b-tr>

                        <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                          <b-td><i class="icon fas fa-moon"></i> {{ msg("TotalNights") }}:</b-td>
                          <b-td>
                            {{ contract.bnbStyleRent.totalNights }}
                          </b-td>
                        </b-tr>
                        <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                          <b-td><i class="icon fas fa-users"></i> {{ msg("Guests") }}:</b-td>
                          <b-td>
                            {{ contract.numberOfGuests }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i>
                            {{ msg("Platform commision") }}:
                          </b-td>
                          <b-td>
                            <b-input-group size="sm">
                              {{ contract.bnbStyleRent.commissionAmount }} <strong>€</strong>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="fa-solid fa-money-bill-transfer"></i>
                            {{ msg("Payment Processing Fee") }}:
                          </b-td>
                          <b-td>
                            {{ contract.bnbStyleRent.paymentProcessingFee }} <strong>€</strong>

                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("Move Date") }}:</b-td>
                          <b-td>
                            <calendar-picker disabled v-model="contract.moveDate"/>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("CheckOut") }}:</b-td>
                          <b-td>
                            <calendar-picker disabled v-model="contract.endDate"/>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="msg('Client')">
              <div v-if="contract != null" class="h-100 pb-2">
                <app-collapse accordion type="margin">

                  <app-collapse-item :title="msg('Profile - Client details for the contract')" is-visible>
                    <b-table-simple responsive>
                      <b-tr>
                        <b-td><i class="icon fas fa-tag"></i> Id:</b-td>
                        <b-td>{{ client.id }}
                          <b-button size="sm" variant="link" @click="openClientAccount"><i
                              class="fa-solid fa-right-to-bracket"></i></b-button>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("Register date") }}:</b-td>
                        <b-td>{{ client.creationTime }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-user"></i> {{ msg("Firstname") }}:</b-td>
                        <b-td class="text-capitalize">
                          {{ contract.client.firstname }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-user"></i> {{ msg("Lastname") }}:</b-td>
                        <b-td class="text-capitalize">
                          {{ contract.client.lastname }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-birthday-cake"></i> {{ msg("Client") }} {{ msg("Birthday") }}:
                        </b-td>
                        <b-td>
                          <span
                              v-if="contract.client.birthday != null && contract.client.birthday.trim() !== '' && contract.client.birthday !== '1970-01-01'">{{ contract.client.birthday | moment('DD/MM/YY') }}</span>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-at"></i> {{ msg("Contract Email") }}:</b-td>
                        <b-td>
                          {{ contract.client.email }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-at"></i> {{ msg("Client Email") }}:</b-td>
                        <b-td style="font-size: 12px">{{ client.email }}</b-td>

                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-phone-alt"></i> {{ msg("Phone") }}:</b-td>
                        <b-td>
                          {{ client.phone }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("address") }}:</b-td>
                        <b-td>
                          {{ contract.client.address.street }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("city") }}:</b-td>
                        <b-td>
                          {{ contract.client.address.city }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("Zip") }}:</b-td>
                        <b-td>
                          {{ contract.client.address.zip }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("country") }}:</b-td>
                        <b-td>
                          {{ contract.client.address.country }}
                        </b-td>
                      </b-tr>

                    </b-table-simple>
                  </app-collapse-item>
                  <app-collapse-item title="Job">
                    <div>
                      <b-table-simple responsive>
                        <b-tr>
                          <b-td><i class="icon fas fa-suitcase"></i> {{ msg("Job Title") }}:</b-td>
                          <b-td>
                            {{ contract.income.jobTitle }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company") }}:</b-td>
                          <b-td>
                            {{ contract.income.companyName }}
                          </b-td>
                        </b-tr>


                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company street") }}:</b-td>
                          <b-td>
                            {{ contract.income.companyAddress.street }}
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company city") }}:</b-td>
                          <b-td>
                            {{ contract.income.companyAddress.city }}
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company country") }}:</b-td>
                          <b-td>
                            {{ contract.income.companyAddress.country }}
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-file-invoice"></i> {{ msg("Netto income") }}:</b-td>
                          <b-td>
                            {{ contract.income.net }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user-clock"></i> {{ msg("Seniority") }}:</b-td>
                          <b-td>
                            {{ contract.income.seniority }}
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </app-collapse-item>

                  <app-collapse-item v-if="contract.card!=null" title="Card">
                    <div>
                      <b-table-simple responsive>
                        <b-tr>
                          <b-td><i class="icon fas fa-file-invoice"></i> {{ msg("Name") }}:</b-td>
                          <b-td>
                            {{ contract.card.name }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user-clock"></i> {{ msg("Number") }}:</b-td>
                          <b-td>
                            {{ contract.card.number }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Date") }}:</b-td>
                          <b-td>
                            {{ contract.card.date }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Cvv") }}:</b-td>
                          <b-td>
                            {{ contract.card.cvv }}
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </app-collapse-item>
                </app-collapse>

              </div>
            </b-tab>
            <!--HOSTAWAY CHAT-->
            <b-tab :title="msg('Chat')">
              <div v-if="contract != null" class="p-2">
                <hostaway-chat-component v-bind:contractId="contractId"
                                         v-bind:reservation-id="contract.hostawayReservationId" full-height :contract="contract"/>
              </div>
            </b-tab>
            <b-tab :title="msg('Payments')">
              <b-card v-if="contract!=null">
                <b-card-header class="d-flex justify-content-between align-items-center">


                  <div class="d-flex align-items-center">
                    <!-- Payment Button -->
                    <b-button style="float: right" variant="warning" @click="openPaymentModal">
                      {{ msg('Make payment') }}
                    </b-button>
                  </div>
                </b-card-header>

                <div>
                  <payment-client-table @submitted="reloadContractToPayment" :payments="contract.clientPayments" :contract-id="contract.id"/>
                </div>
              </b-card>
            </b-tab>
            <b-tab :title="msg('Damages')">
              <div v-if="contract != null" class="p-2">

                <contract-damage-component v-bind:contract-id="contract.id"/>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else>
          <b-tabs v-model="tabsIndex" align="center">
            <b-tab :title="msg('Contract')" active>
            </b-tab>
            <b-tab :title="msg('Client')">
            </b-tab>
            <b-tab :title="msg('Chat')">
            </b-tab>
            <b-tab :title="msg('Payments')">
            </b-tab>
            <b-tab :title="msg('Damages')">
            </b-tab>
          </b-tabs>
          <b-skeleton-table
              :rows="15"
              :columns="2"
          ></b-skeleton-table>
        </div>

      </b-sidebar>
    </portal>
    <b-modal
        v-if="contract != null"
        no-fade
        v-model="dialogs.payment"
        size="l"
        ref="payment-modal"
        id="payment-modal"
        :hide-footer="true"
        :title="'Customer: ' + contract.client.lastname + ' ' + contract.client.firstname + ' - Stripe Payment'"
        no-close-on-backdrop
        centered>

      <div class="mt-2 text-center">
        <!-- Dropdown to select payment type -->
        <label>{{ msg("Type") }}</label>

        <v-select
            v-model="paymentType"
            class="mb-2"
            :clearable="false"
            :options="paymentOptions"
            label="label"
            :reduce="(branch) => branch.value"
            placeholder="Select payment type">
        </v-select>

        <!-- Credit Card Payment Fields -->
        <div v-if="paymentType === 'Credit card'">
          <label>{{ msg("Reason") }}</label>
          <b-form-input v-model="reason" class="mb-2"></b-form-input>
          <label>{{ msg("Amount") }}</label>
          <b-form-input v-model="paymentAmount" type="number" step="0.01" min="0" max="3000"
                        class="mb-2"></b-form-input>
          <b-button
              :disabled="paymentAmount == null || paymentAmount <= 0.0 || paymentLoading || reason == null"
              variant="outline-success"
              class="btn-dialog"
              @click="makePayment">
            {{ msg('Charge the customer now') }}
          </b-button>
        </div>

        <!-- Cash or Wire Transfer Fields -->
        <div v-else-if="paymentType === 'Cash' || paymentType === 'Wire transfer'">
          <label>{{ msg("Reason") }}</label>
          <b-form-input v-model="reason" class="mb-2"></b-form-input>
          <label>{{ msg("Amount") }}</label>
          <b-form-input v-model="paymentAmount" type="number" step="0.01" min="0" class="mb-2"></b-form-input>
          <b-form-checkbox class="mb-2" v-model="isPaid" name="check-button" size="sm" switch>
            {{ isPaid ? msg("Paid") : msg("Not paid") }}
          </b-form-checkbox>
          <b-form-datepicker v-model="paymentDate" :min="minDate" placeholder="Select date"
                             class="mb-2"></b-form-datepicker>
          <b-button
              :disabled="!paymentDate || paymentAmount == null || paymentAmount <= 0.0 || paymentLoading || reason == null"
              variant="outline-success"
              class="btn-dialog"
              @click="makePayment">
            {{ msg('Confirm Payment') }}
          </b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import HostawayChatComponent from "@/views/realEstate/contracts/HostawayChatComponent.vue";
import PaymentClientTable from "@/views/realEstate/contracts/paymentClientTable.vue";
import ContractDamageComponent from "@/views/realEstate/contracts/ContractDamageComponent.vue";

export default {
  name: "ContractRentSidebar",
  components: {ContractDamageComponent, PaymentClientTable, HostawayChatComponent, AppCollapse, AppCollapseItem},
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getContract();
        }
      }
    },
    'sidebar.visible'() {
      if (this.isBodyScrollDisabled !== this.sidebar.visible) {
        this.DISABLE_BODY_SCROLL(this.sidebar.visible);
      }
    }
  },
  data: () => ({
    loading: null,
    sidebar: {
      visible: false
    },
    dialogs: {sms: false, payment: false},

    tabsIndex: 0,
    contractId: null,
    contract: null,
    property: null,
    client: null,
    permissions: null,
    paymentAmount: 0.0,
    reason: null,
    paymentLoading: false,
    paymentType: null, // Dropdown selection value
    isPaid: false, // Checkbox value for cash or wire transfer
    paymentDate: null, // Selected date for payment
  }),
  computed: {
    ...mapGetters("appConfig", ["isBodyScrollDisabled"]),
    paymentOptions() {
      return [
        {label: this.msg('Credit Card'), value: 'Credit card'},
        {label: this.msg('Cash'), value: 'Cash'},
        {label: this.msg('Wire Transfer'), value: 'Wire transfer'}
      ];
    },
  },
  methods: {
    ...mapActions("realEstateContracts", ["createPdfInvoiceContract", "getById", "updateContract", "sproofRequest", "cancelContract", "changeContract",
      "updateStatus", "updateFilesData", "updateApproveStatus", "changeContractOwner",
      "updateEditableFields", "updateDocumentFile", "generateAndSendLink", "sendCheckInDetails", "getMonetaryId"]),
    ...mapActions("clients", ["generateToken"]),
    ...mapMutations('appConfig', ['DISABLE_BODY_SCROLL']),
    ...mapActions("monetary", ["chargeCustomer"]),

    makePayment() {
      let $this = this;
      this.paymentLoading = true
      this.chargeCustomer({
        contractId: this.contract.id,
        amount: this.paymentAmount,
        reason: this.reason,
        type: this.paymentType,
        paid: this.isPaid,
        date: this.paymentDate
      }).then(approve => {
        $this.dialogs.payment = false
      }).finally(() => {
            $this.paymentLoading = false
            $this.paymentAmount = 0.0
            $this.reason = null
            $this.paymentType = null
            $this.isPaid = false
            $this.paymentDate = null
            $this.dialogs.payment = false
            $this.reloadContractToPayment();
          }
      )

    },
    openPaymentModal() {

      this.dialogs.payment = true

    },
    reloadContractToPayment() {
      this.sidebar.visible = false;
      this.sidebar.visible = true;
      this.tabsIndex = 3;
      this.getContract();
    },
    showContract(contractId) {
      console.log('showContract', contractId)
      this.contractId = contractId;
      this.sidebar.visible = true;
      this.tabsIndex = 0;
      this.getContract();
    },
    getContract() {
      if (this.contractId == null || this.contractId === "") return;
      let $this = this;
      let contractId = this.contractId;
      this.loading = contractId;
      this.getById(this.contractId).then(this.onContractReceive, this.onContractRetrieveError)
          .finally(() => {
            if ($this.loading === contractId) {
              $this.loading = null
            }
          });
    },
    onContractReceive(data) {
      let $this = this;
      this.contract = data.contract;
      this.contract.statusHistories = this.contract.statusHistories.sort((a, b) => $this.$moment(b.time, 'YYYY-MM-DD HH:mm:ss').diff($this.$moment(a.time, 'YYYY-MM-DD HH:mm:ss')))
      this.property = data.property;
      this.client = data.client;
      this.permissions = data.permissions;
      this.allContractStatus = data.statuses;
    },
    openClientAccount() {
      this.generateToken(this.client.id).then(res => {
        window.open(res.details.sitePath, '_blank');
      })
    }
  }
}
</script>


<style scoped>

</style>
